import React from "react";




export default function Navbar(props) {



    return (

        <>
        <div class="navbar min-h-[10vh] bg-base-200"><div class="flex-1"><a href="/" class="btn btn-ghost font-1 text-3xl">theDailySat</a></div><div class="dropdown dropdown-end dropdown-hover"><div tabindex="0" role="button" class="btn btn-ghost rounded-btn items-center font-1 ">More<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-4"><path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd"></path></svg></div><ul class="dropdown-content menu p-5 bg-base-100 w-52 shadow-md z-20 rounded-box font-1 font-semibold"><li class=""><a href="/privacy" class="btn btn-ghost">Privacy Policy</a></li><li class=""><a href="/terms" class="btn btn-ghost">Terms and Conditions</a></li></ul></div></div>
        
        </>
    )
}