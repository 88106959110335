import React from "react";





export default function Loading(props) {



    return (
        <>
        <div className="fixed h-screen w-screen opacity-70 bg-black z-40 top-0 left-0"></div>

        <div className="fixed z-50 top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%]">
            <div className="w-fit mx-auto">
                <span className="loading loading-bars loading-lg text-accent"></span>
            </div>
            
            <p className="font-2 text-lg text-center text-white mt-4">Give us a moment while we register you in our systems.</p>


        </div>
        
        
        
        
        </>
    )
}