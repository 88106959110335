import React from "react"
import {BrowserRouter, Routes,Route} from "react-router-dom"
import Index from "./Index"
import Privacy from "./Privacy"
import Terms from "./Terms"
import Loading from "./Loading"
import NotFound from "./NotFound"
export default function App(props) {


    return (
        
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Index />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/*" element={<NotFound/>} />
            </Routes>
        </BrowserRouter>
    
)




}