import React from "react";
import Footer from "./Footer"



export default function NotFound(props) {





    return (

        <>



            <div className="hero min-h-screen">
                
                <div className=" rounded-box p-12 flex items-center sm:flex-row flex-col sm:text-left text-center gap-12 bg-base-300">
                    
                    <div>
                    <h2 className="text-8xl">😞</h2>
                    </div>
                    

                    <div className="flex flex-col gap-4">
                    <p className="font-2 font-bold text-4xl">404</p>
                    <p className="font-2 text-lg">You look really lost over here...</p>

                    <a href="/" className="btn w-full btn-accent font-2">Back to safety<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  <path fillRule="evenodd" d="M9.53 2.47a.75.75 0 0 1 0 1.06L4.81 8.25H15a6.75 6.75 0 0 1 0 13.5h-3a.75.75 0 0 1 0-1.5h3a5.25 5.25 0 1 0 0-10.5H4.81l4.72 4.72a.75.75 0 1 1-1.06 1.06l-6-6a.75.75 0 0 1 0-1.06l6-6a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
</svg>
</a>

x
                    </div>
                
                </div>
                
                

            </div>

            <Footer />




        
        </>
    )
}