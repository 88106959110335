import React, {useState} from "react"

import callApi from "./CallApi"
import Loading from "./Loading"








export default function Newsletter(props) {
    const [enableSignup, setEnableSignup] = useState(false)
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const changeEmail = (value) => {
        setEmail(value)

        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (regex.test(value)) {
            setEnableSignup(true)
        } else {
            setEnableSignup(false)
        }


    }


    const addUser = () => {
        setLoading(true)
        callApi("/addUser", "POST", {email: email}).then((res) => {
            setLoading(false)
            console.log(res)
            setSuccess(true)
        })


    }




    return (

        <>
      
       
        
        <div class="bg-neutral w-full font-2 min-h-[60vh] hero" id="newsletter">
        {(loading) && (
            <Loading />
        )}
            <div class="hero-content md:p-8 bg-base-300 flex-col md:w-4/6  w-full sm:h-5/6 h-full md:rounded-lg">
                {!success ? 
                <>
                <p class="font-2 font-bold text-3xl text-center">Daily practice Made Convenient</p>
                <div>
                    <p class="font-1 font-semibold text-lg md:text-left text-center">Receive Hard Sat questions (like those) straight to your inbox</p>
                </div>
                <div class="join md:join-horizontal join-vertical">
                    
                    
                    {enableSignup ? 
                    <>
                        <input onChange={(e) => changeEmail(e.target.value)} maxlength="100" type="email" class="input input-success join-item text-lg font-1 font-semibold md:input-lg" placeholder="Your Email" value={email} />
                        <button class="btn join-item btn-success font-1 font-bold rounded-r-lg md:btn-lg text-xl" onClick={addUser}>Subscribe</button>

                    
                    </>
                                       :

                                       <>
                                       <input onChange={(e) => changeEmail(e.target.value)} maxlength="100" type="email" class="input input-error join-item text-lg font-1 font-semibold md:input-lg" placeholder="Your Email" value={email} />
                                       <button class="btn join-item btn-disabled font-1 font-bold rounded-r-lg md:btn-lg text-xl">Subscribe</button>
                                       </>
                    
                }   
                                    </div>
                <div class="flex flex-row gap-2">
                    <input type="checkbox" class="checkbox" value="false" />
                    <p class="font-1">I accept theDailySat.com's legal notice, private policy and cookie policy</p>
                </div>
                <div class="text-sm font-1"><p>Unsubscribe anytime. Guaranteed*</p> </div>
                </>
            
            
                :
                
                <>
                <h1 class="text-5xl">😳</h1><h3 class="text-center text-4xl font-semibold font-1">You're all set!</h3><p class="font-2 text-lg">You should expect a daily SAT question every morning</p><p>Happy Studying!</p>
                </>
                }
                
           
        </div>
    </div>
    </>
    )
}